.section2-container{
    margin-block: 3em;
    display: flex;
    gap: 3em;
}

.about h1{
    color: #142B3C;
}

.about p{
    margin-block: 1em;
    color: #4B5565;
}

.img-super-container{
 display: flex;
 gap: 2em;
}

@media screen and (max-width:1024px){
    .section2-container {
        display: block;
        padding: 1em;
        text-align: center;
    }

    .about{
        margin-bottom: 1em;
    }
    
    .img-super-container{
        display: flex;
        justify-content: center;
        gap: 2em;
        margin-top: 1em;
       }


  }

  @media screen and (max-width:600px){
    .img-super-container{
        display: block;
        margin-top: 1em;
       }

    .img-container img{
        width: 80%;
    }
  }