.footer-main-container{
    display: flex;
    justify-content: center;
}

.footer-super-container {
  margin-top: 1em;
  padding-block: 1em;
  color: #fff;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  margin-block: 1em;
}

.divider-container {
  display: flex;
  justify-content: center;
}

.divider {
  width: 1216px;
  height: 1px;
  background: #475467;
}

.footer-b-container {
  margin-block: 1em;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #98a2b3;
}

@media screen and (max-width:1024px){
  .footer-container{
      display: block;
      padding: 1em;
      text-align: center;
  }

  .divider{
    width: 80%;
  }


}

